.mainHeader{
	/*height:60px;*/
  height:70px;
	width:100%;
	top:0px;
	position:fixed;
	padding:4px;
	background-color:#FFFFFF;
	box-shadow:1px 1px #f0f0f5;
}
.mainFooter{
	padding:2px;
	background-color:#e0e0eb;
	position:0;
}
.columnResponsive {
  float: left;
  width: 33%;
}

/* Clear floats after the columns */
.rowResponsive:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width:740px) {
  .columnResponsive {
    width: 100%;
  }
  .columnResponsive70percent {
    width: 100%;
  }
  .columnResponsive15percent {
    width: 100%;
  }
  .columnResponsive10percent {
    width: 100%;
  }
}
.columnResponsive70percent {
  float: left;
  width: 70%;
}
.columnResponsive15percent {
  float: left;
  width: 15%;
}
.columnResponsive10percent {
  float: left;
  width: 10%;
}
.columnResponsive50percent {
  float: left;
  width: 50%;
}
.columnResponsive40percent {
  float: left;
  width: 40%;
}
.columnResponsive33percent {
  float: left;
  width: 33%;
}
.columnResponsive25percent{
  float:left;
  width:25%;
}
.columnResponsive6percent{
  float:left;
  width:7%;
}
.columnResponsive56percent{
  float:left;
  width:56%;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}